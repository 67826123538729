import React, { Component } from "react";
import styles from "./detail.module.less";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink } from "react-router-dom";
// import classNames from "classnames";

export default class DetailAlbum extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      listData: [],
      startRef: null,
      step: 1,
      interval: null,
    };
  }

  getCurAlbumIndex = () => {
    const routePathname = this.props.history.location.pathname;
    let infoStr = routePathname.substring(0, 7) === "/album/" ? routePathname.substring(7) : "";
    return Number(infoStr);
  };

  handleScrolling = () => {
    this.wrapperRef.scrollLeft = (this.wrapperRef.scrollLeft || 0) + this.state.step;
  };

  UNSAFE_componentWillMount() {
    this.props.changeColorFun(true);
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  componentDidMount() {
    let count = 0;
    config.data.forEach((parent) => {
      if (parent && parent.children) {
        parent.children.forEach((child) => {
          count++;
          child.numInAll = count;
        });
      }
    });
    let numNow = this.getCurAlbumIndex();
    const albumItemObj = config.data[numNow - 1];
    const list = (albumItemObj || {}).children || [];
    this.setState({
      listData: [...list, ...list, ...list, ...list],
    });
    setTimeout(() => {
      const aListRef = document.querySelectorAll("[data-img]");
      this.setState({
        startRef: [...aListRef][list.length].parentNode,
        interval: setInterval(() => {
          if (!this.wrapperRef) return;
          if (this.state.startRef.getBoundingClientRect().left < 3) {
            this.wrapperRef.scrollLeft = 0;
          } else {
            this.handleScrolling();
          }
        }, 30),//此处调整图片播放速度
      });
    });
  }

  render() {
    const t = this;
    return (
      <div className={styles.wrapper}>
        <PerfectScrollbar
          component="div"
          containerRef={(ref) => {
            this.wrapperRef = ref;
          }}
          className={styles.wrapper_inside}
        >
          {t.state.listData.map((child, index) => {
            return (
              <NavLink
                key={`child_${index}`}
                className={styles.item}
                to={{
                  pathname: `/detail/${child.numInAll}`,
                }}
              >
                <img src={child.url} alt={`child_${index}`} data-img />
              </NavLink>
            );
          })}
        </PerfectScrollbar>
      </div>
    );
  }
}
