import React, { Component } from "react";
import styles from "./information.module.less";

export default class Infomation extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.contaner}>
          <div className={styles.imgContainer}>
            <img src="" alt="" />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.mb15}>
              <p>Yingda Xu(徐英达) is a Chinese film photographer and graphic designer currently based in Guangzhou, China.</p>
              <p>In 2015, he graduated from Swinburne University in Melbourne, Australia with a bachelor of Visual Communication Design specialising in Photography.</p>
            </div>
            <p className={styles.mb15}>For business inquiries please contact me via email.</p>
            <div className={styles.mb55}>
              <p>
                <b>Email:</b> yingdaxu@outlook.com
              </p>
              <p>
                <b>Wechat:</b> Shawnxu92
              </p>
              <p>
                <b>Instagram: </b>
                <a href="https://www.instagram.com/whatisthisstyle/" style={{ color: "black" }}>
                  Whatisthisstyle
                </a>
              </p>
            </div>
            <div>
              <p>
                <b>Exhibitions:</b>
              </p>

              <p>
                <a href="https://www.instagram.com/roserougetokyo/" style={{ color: "black" }}>
                  Rose Rogue Tokyo 2019 (Japan)
                </a>
              </p>
              <p>
                <a href="https://palmstudios.co.uk/palm-photo-prize-18/palm-photo-prize-2018/" style={{ color: "black" }}>
                  Palm Photo Prize 2018 (London)
                </a>
              </p>
            </div>

            <div>
              <p>
                <b>Press:</b>
              </p>
              <p>
                <a href="https://www.aint-bad.com/article/2018/01/13/yindga-xu/" style={{ color: "black" }}>
                  Aint-bad,{" "}
                </a>
                Ayemag, Pample-Mousse, Altair Zine
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
