import React, { Component } from "react";
import styles from "./thumbnails.module.less";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink } from "react-router-dom";

export default class Thumbnails extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    this.props.changeColorFun(true);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyUpFun); //绑定键盘事件
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyUpFun);
  }

  //键盘事件
  keyUpFun = (e) => {
    switch (e.keyCode) {
      //UP
      case 38:
        this.handleScrollUp();
        break;
      //DOWN
      case 40:
        this.handleScrollDown();
        break;
      default:
        break;
    }
  };

  handleScrollUp() {
    this.wrapperRef.scrollTop = (this.wrapperRef.scrollTop || 0) - 80;
  }

  handleScrollDown() {
    this.wrapperRef.scrollTop = (this.wrapperRef.scrollTop || 0) + 80;
  }

  render() {
    const isTablet = window.innerWidth < 1028 && window.innerWidth > 600;
    const isMobile = window.innerWidth <= 600;
    let count = 0;
    config.data.forEach((parent) => {
      if (parent && parent.children) {
        parent.height = isTablet ? parent.tablet_height || 139 : parent.web_height || 149;
        parent.children.forEach((child) => {
          count++;
          child.numInAll = count;
        });
      }
    });

    return (
      <div className={styles.wrapper}>
        <PerfectScrollbar
          containerRef={(ref) => {
            this.wrapperRef = ref;
          }}
          speed={0.8}
          className={styles.wrapper_inside}
        >
          {config.data.map((parent, index) => {
            return (
              <div className={styles.container} key={`parent_${index}`}>
                {parent.children.map((item, index) => {
                  return (
                    <NavLink
                      key={`child_${index}`}
                      className={styles.item}
                      style={{ height: isMobile ? "unset" : parent.height + "px" }}
                      to={{
                        pathname: `/detail/${item.numInAll}`,
                      }}
                    >
                      <img src={item.url} alt="" style={{ height: isMobile ? "unset" : parent.height + "px" }} />
                    </NavLink>
                  );
                })}
              </div>
            );
          })}
        </PerfectScrollbar>
      </div>
    );
  }
}
